import { SbBlokData, storyblokEditable } from "@storyblok/react";
import Link from "next/link";
import { FC } from "react";
import { SbLink } from "types/storyblock";

interface Props extends SbBlokData {
    link: SbLink;
    heading: string;
    text: string;
    button_label: string;
}

const Slide: FC<{ blok: Props }> = ({ blok }) => {
    if (!blok) {
        return null;
    }

    return (
        <div className="swiper-slide" {...storyblokEditable(blok)}>
            <h2 className="display-2 from-start mb-lg-4">{blok.heading}</h2>
            <div className="from-end">
                <p className="fs-xl text-light opacity-70 pb-2 mb-lg-5">{blok.text}</p>
            </div>
            {blok.link.cached_url && (
                <div className="scale-up delay-1">
                    <Link
                        className="btn btn-primary shadow-primary btn-lg"
                        href={blok.link.cached_url}>
                        {blok.button_label}
                    </Link>
                </div>
            )}
        </div>
    );
};

export default Slide;
