export const enum StoryBlockComponentsEnum {
    Hero = "hero",
    Features = "features",
    Video = "video",
    Categories = "categories",
    Team = "team",
    Reviews = "reviews",
    News = "news",
    Contacts = "contacts",
    Prices = "prices",
    Services = "services",
    Stats = "stats",
    Brands = "brands",
    AboutContent = "about_content",
    Slider = "slider",
}
