import {
    Dispatch,
    FC,
    PropsWithChildren,
    SetStateAction,
    createContext,
    useMemo,
    useState,
} from "react";
import { Language } from "utils/enums";

export interface ILocaleContext {
    locale: Language;
    setLocale: Dispatch<SetStateAction<Language>>;
}

export const LocaleContext = createContext<ILocaleContext>(null as any);

type Props = {
    locale: Language;
};

export const LocaleProvider: FC<Props & PropsWithChildren> = ({ children, locale }) => {
    const [loc, setLoc] = useState(locale);

    const value = useMemo(
        () => ({
            locale: loc,
            setLocale: setLoc,
        }),
        [loc, setLoc],
    );

    return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
};
