export const splitArrIntoChunks = (arr: any[], chunkSize: number) => {
    const chunks = [];

    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        chunks.push(chunk);
    }
    return chunks;
};

export function splitArray(arr: any[], amount: number) {
    const chunkLength = Math.max(arr.length / amount, 1);
    const chunks = [];
    for (let i = 0; i < amount; i++) {
        if (chunkLength * (i + 1) <= arr.length)
            chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
    }
    return chunks;
}
