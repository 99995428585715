import { storyblokEditable } from "@storyblok/react";
import { FC } from "react";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import Img from "components/common/img/Img";
import { SbHeaderContent } from "types/storyblock";
import { useRuntimeConfig } from "hooks/useRuntimeConfig";
import NavBar from "./nav-bar/NavBar";

type IProps = {
    content: SbHeaderContent;
};

const Header: FC<IProps> = ({ content }) => {
    const APP_NAME = useRuntimeConfig("APP_NAME");
    const { t } = useTranslation();
    return (
        <header
            className="header navbar navbar-expand-lg bg-light navbar-sticky red green"
            {...storyblokEditable(content)}>
            <div className="container px-3">
                <Link href="/" className="navbar-brand pe-3">
                    {content.logo?.filename ? (
                        <Img
                            source={content.logo.filename}
                            alt={`${APP_NAME} logo`}
                            title={`${APP_NAME} logo`}
                            width={115}
                            height={0}
                            // style={{ width: "100%", height: "auto" }}
                        />
                    ) : (
                        APP_NAME
                    )}
                </Link>

                <NavBar nav={content.header_nav} phone={content.phone} />

                {content.theme_switcher && (
                    <div
                        className="form-check form-switch mode-switch pe-lg-1 ms-auto me-4"
                        data-bs-toggle="mode">
                        <input type="checkbox" className="form-check-input" id="theme-mode" />
                        <label className="form-check-label d-none d-sm-block" htmlFor="theme-mode">
                            Light
                        </label>
                        <label className="form-check-label d-none d-sm-block" htmlFor="theme-mode">
                            Dark
                        </label>
                    </div>
                )}

                <button
                    type="button"
                    className="navbar-toggler"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                {content.phone && (
                    <a
                        href={`tel:${content.phone}`}
                        className="btn btn-primary btn-sm fs-sm rounded d-none d-lg-inline-flex"
                        target="_blank"
                        rel="noopener">
                        <i className="bx bx-phone-call fs-5 lh-1 me-1" />
                        {t("base_call")}
                    </a>
                )}
            </div>
        </header>
    );
};

export default Header;
