import { SbBlokData, StoryblokComponent } from "@storyblok/react";
import { FC } from "react";

type IProps = {
    nav?: SbBlokData[];
    phone?: string;
};

const NavBar: FC<IProps> = ({ nav, phone }) => {
    return (
        <div id="navbarNav" className="offcanvas offcanvas-end">
            <div className="offcanvas-header border-bottom">
                <h5 className="offcanvas-title">Menu</h5>
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    {nav?.map((link) => (
                        <li className="nav-item" key={link._uid}>
                            <StoryblokComponent blok={link} className="nav-link" />
                        </li>
                    ))}
                </ul>
            </div>
            {phone && (
                <div className="offcanvas-header border-top">
                    <a
                        href={`tel:${phone}`}
                        className="btn btn-primary w-100"
                        target="_blank"
                        rel="noopener">
                        <i className="bx bx-cart fs-5 lh-1 me-1"></i>
                        &nbsp;Թեժ գիծ
                    </a>
                </div>
            )}
        </div>
    );
};

export default NavBar;
