import { FC, useRef } from "react";
import { SbBlokData } from "@storyblok/react";
import Img from "components/common/img/Img";
import { SbAsset, SbLink } from "types/storyblock";
import ClientOnlyComponent from "./client-only-component/ClientOnlyComponent";

interface VideoContent extends SbBlokData {
    title?: string;
    sub_title?: string;
    video_link?: SbLink;
    video_file?: SbAsset;
}

type Props = {
    blok: VideoContent;
};

export const Video: FC<Props> = ({ blok }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    // const [isPlaying, setIsPlaying] = useState(false);

    /* const handlePlayPause = () => {
        if (isPlaying) {
            videoRef.current?.pause(); // Pause the video
        } else {
            videoRef.current?.play(); // Play the video
        }
        setIsPlaying(!isPlaying); // Toggle play state
    }; */

    return (
        <ClientOnlyComponent>
            <section className="container text-center pb-5 mb-3 mb-md-4 mb-lg-5">
                <h2 className="h1 pt-1 mb-4">{blok.title}</h2>
                <div className="row justify-content-center mb-md-2 mb-lg-5">
                    <div className="col-lg-6 col-md-8">
                        <p className="fs-lg text-muted mb-lg-0">{blok.sub_title}</p>
                    </div>
                </div>
                <div className="position-relative rounded-3 overflow-hidden mb-lg-3">
                    <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center zindex-5">
                        {/* {blok.video_link && (
                        <a
                            href="https://www.youtube.com/watch?v=wJC1LFT_GD0"
                            className="btn btn-video btn-icon btn-xl stretched-link bg-white"
                            data-bs-toggle="video"
                            aria-label="Play video">
                            <i className="bx bx-play"></i>
                        </a>
                    )} */}
                        {blok.video_link && (
                            <>
                                <video
                                    ref={videoRef}
                                    src="https://www.youtube.com/watch?v=wJC1LFT_GD0"
                                    className="btn btn-video btn-icon btn-xl stretched-link bg-white"
                                    data-bs-toggle="video"
                                    aria-label="Play video">
                                    <i
                                        className="bx bx-play"
                                        // onClick={handlePlayPause}
                                    />
                                </video>
                            </>
                        )}
                    </div>
                    <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-35"></span>
                    <Img
                        source="assets/img/landing/medical/video-cover.jpg"
                        alt="Cover image"
                        width={0}
                        height={0}
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            </section>
        </ClientOnlyComponent>
    );
};
