import { FC, createContext, useMemo } from "react";
import { IRuntimeConfig } from "types/siteConfig";

type IProps = {
    children: React.ReactNode;
    config: IRuntimeConfig;
    commitHash?: string;
};

export const RuntimeConfigContext = createContext<IRuntimeConfig>(null as any);

export const RuntimeConfigProvider: FC<IProps> = ({ children, config, commitHash }) => {
    const configMemo = useMemo(
        () => ({ ...config, RELEASE_VERSION: commitHash?.substring(0, 4) || "local" }),
        [commitHash, config],
    );
    return (
        <RuntimeConfigContext.Provider value={configMemo}>{children}</RuntimeConfigContext.Provider>
    );
};
