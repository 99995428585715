import { FC, PropsWithChildren } from "react";
import { Inter } from "next/font/google";
import { ISbStoryData } from "@storyblok/react";
import Footer from "components/footer/Footer";
import Header from "components/header/Header";

const inter = Inter({ subsets: ["latin"] });

interface Props {
    header: ISbStoryData;
    footer: ISbStoryData;
}

const MainLayout: FC<PropsWithChildren & Props> = ({ children, header, footer }) => {
    return (
        <>
            <main className={`page-wrapper ${inter.className}`}>
                {header && <Header content={header.content} />}

                {children}
            </main>
            {footer && (
                <Footer
                    content={footer.content}
                    logo={header.content.logo}
                    phone={header.content.phone}
                />
            )}
        </>
    );
};

export default MainLayout;
