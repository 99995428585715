import { FC, useContext, useMemo } from "react";
import Link from "next/link";
import { TeamContext } from "providers/team.provider";
import { CategoriesContext } from "providers";
import Img from "./img/Img";

export const Team: FC = () => {
    const { team } = useContext(TeamContext);
    const { selectedCategory } = useContext(CategoriesContext);

    const filteredTeam = useMemo(
        () =>
            selectedCategory
                ? team.filter((member) => member.tags.includes(selectedCategory.slug))
                : team.filter((_member, index) => index <= 3),
        [selectedCategory, team],
    );

    if (!filteredTeam.length) {
        return null;
    }

    return (
        <section className="container pt-xl-2 pb-5 mb-md-3 mb-lg-5">
            <div className="d-md-flex align-items-center justify-content-between text-center text-md-start pb-1 pb-lg-0 mb-4 mb-lg-5">
                <h2 className="h1 mb-md-0">Որակավորված բժիշկներ</h2>
                {/* <a href="#" className="btn btn-outline-primary">
                    Տեսնել բոլոր բժիշկներին
                    <i className="bx bx-right-arrow-alt fs-xl ms-2 me-n1"></i>
                </a> */}
            </div>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
                {filteredTeam.map((member) => (
                    <div key={member._uid} className="col">
                        <div className="card card-hover border-0 bg-transparent">
                            <div className="position-relative">
                                <Img
                                    source={member.img.filename}
                                    className="rounded-3"
                                    alt={member.full_name}
                                    width={0}
                                    height={0}
                                    style={{ width: "100%", height: "auto" }}
                                />
                                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                                    {(member.facebook || member.instagram) && (
                                        <>
                                            <span className="position-absolute top-0 start-0 w-100 h-100 bg-primary opacity-35 rounded-3"></span>
                                            <div className="position-relative d-flex zindex-2">
                                                {member.facebook.url && (
                                                    <a
                                                        href="#"
                                                        className="btn btn-icon btn-secondary btn-facebook btn-sm bg-white me-2"
                                                        aria-label="Facebook">
                                                        <i className="bx bxl-facebook"></i>
                                                    </a>
                                                )}
                                                {member.instagram.url && (
                                                    <Link
                                                        href={member.instagram.url}
                                                        type="_blank"
                                                        className="btn btn-icon btn-secondary btn-instagram btn-sm bg-white me-2"
                                                        aria-label="Instagram">
                                                        <i className="bx bxl-instagram"></i>
                                                    </Link>
                                                )}

                                                {/* <a
                                            href="#"
                                            className="btn btn-icon btn-secondary btn-twitter btn-sm bg-white"
                                            aria-label="Twitter">
                                            <i className="bx bxl-twitter"></i>
                                        </a> */}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="card-body text-center p-3">
                                <h3 className="fs-lg fw-semibold pt-1 mb-2">{member.full_name}</h3>
                                <p className="fs-sm mb-2">{member.speciality}</p>
                                {/* <div className="d-flex align-items-center justify-content-center">
                                <div className="text-nowrap me-1">
                                    <i className="bx bxs-star text-warning fs-sm"></i>
                                    <i className="bx bxs-star text-warning fs-sm"></i>
                                    <i className="bx bxs-star text-warning fs-sm"></i>
                                    <i className="bx bxs-star text-warning fs-sm"></i>
                                    <i className="bx bxs-star text-warning fs-sm"></i>
                                </div>
                                <span className="fs-xs text-muted">(19 reviews)</span>
                            </div> */}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};
