import { useState, useEffect, useCallback, CSSProperties, FC } from "react";
import Image from "next/image";
import classnames from "classnames";
import { ComponentProps } from "react";

type ImageProps = Omit<ComponentProps<typeof Image>, "src">;

export interface ImgProps extends ImageProps {
    source: string;
    alt: string;
    wrapperClassName?: string;
    style?: CSSProperties;
}

const Img: FC<ImgProps> = ({ source, wrapperClassName, alt, style, ...rest }) => {
    const [imgSrc, setImgSrc] = useState(source);
    const [ready, setReady] = useState(false);

    const onLoadCallback = useCallback(() => setReady(true), []);

    useEffect(() => {
        setImgSrc(source);
    }, [source]);

    return (
        <span className={classnames("imageNext", wrapperClassName)}>
            <Image
                quality={100}
                alt={alt}
                src={imgSrc}
                style={{
                    ...style,
                    opacity: ready ? 1 : 0,
                    transition: "opacity 0.3s",
                }}
                unoptimized
                {...rest}
                onLoad={onLoadCallback}
            />
        </span>
    );
};

export default Img;
