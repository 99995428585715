import { I18nextProvider as ReactI18nextProvider, initReactI18next } from "react-i18next";
import i18next, { i18n } from "i18next";
import { useContext } from "react";
import { TranslationScopes, Language } from "utils/enums";
import { LocaleContext } from "./locale.provider";

type IResourceBundles = { [scope: string]: { [key: string]: string } };

const createI18n = (() => {
    let instance: i18n | null = null;

    return (locale: string, resourcesFromServer: IResourceBundles) => {
        if (instance) {
            for (const scope in resourcesFromServer) {
                instance.addResourceBundle(
                    locale,
                    scope,
                    resourcesFromServer[scope as TranslationScopes],
                );
            }

            if (instance.language !== locale) {
                void instance.changeLanguage(locale);
            }

            return instance;
        }

        instance = i18next.use(initReactI18next).createInstance(
            {
                fallbackLng: Language.en,
                lng: locale,
                debug: false,
                defaultNS: TranslationScopes.Common,
                resources: {
                    [locale]: resourcesFromServer,
                },
                interpolation: {
                    escapeValue: false,
                },
            },
            // callback forces init
            (err) => {
                if (err) return console.log("something went wrong while initializing i18n", err);
            },
        );

        return instance;
    };
})();

type IWithTranslationsProps = {
    translations: IResourceBundles;
    children: React.ReactNode;
};

export const I18nextProvider = (props: IWithTranslationsProps) => {
    const { locale } = useContext(LocaleContext);
    const i18n = createI18n(locale, props.translations);

    return (
        <ReactI18nextProvider i18n={i18n} defaultNS={TranslationScopes.Common}>
            {props.children}
        </ReactI18nextProvider>
    );
};

export { I18nContext, useTranslation } from "react-i18next";
