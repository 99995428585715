import { FC, PropsWithChildren, ReactNode, useEffect } from "react";
import { AppProps } from "next/app";
import Script from "next/script";
import { storyblokInit } from "@storyblok/react";
import { useRouter } from "next/router";
import MainLayout from "components/layouts/MainLayout";
import { CategoriesProvider } from "providers/categories.provider";
import { CommonSsrProps } from "types/storyblock";
import { I18nextProvider, LocaleProvider } from "providers";
import { TeamProvider } from "providers/team.provider";
import { RuntimeConfigProvider } from "providers/runtime.config.provider";
import { AppPropsWithLayout } from "types/next";
import ActiveLink from "components/common/active-link/ActiveLink";
import "../styles/main.css";
import Slide from "components/common/slider/Slide";
import Slider from "components/common/slider/Slider";
import Carousel from "components/common/carousel/Carousel";
import { Hero } from "components/common/Hero";
import { Video } from "components/common/Video";
import { Features } from "components/common/Features";
import { Reviews } from "components/common/Reviews";
import { Team } from "components/common/Team";
import { News } from "components/common/News";
import { Contacts } from "components/common/Contacts";
import { Categories } from "components/common/Categories";
import Stats from "components/pages/about/Stats";
import Brands from "components/pages/about/Brands";

const components = {
    /* feature: Feature,
    header_menu: NavBar, */
    brands: Brands,
    stats: Stats,
    categories: Categories,
    contacts: Contacts,
    news: News,
    team: Team,
    reviews: Reviews,
    features: Features,
    video: Video,
    hero: Hero,
    carousel: Carousel,
    active_link: ActiveLink,
    slider: Slider,
    slide: Slide,
};

storyblokInit({
    components,
});

type IProvidersProps = PropsWithChildren & {
    pageProps: CommonSsrProps;
};

const Providers: FC<IProvidersProps> = ({ children, pageProps }) => {
    return (
        <LocaleProvider locale={pageProps.locale}>
            <I18nextProvider translations={pageProps.translations}>
                <CategoriesProvider
                    defaultCategories={pageProps.categories || undefined}
                    selectedCategory={pageProps.category || undefined}>
                    <TeamProvider defaultTeam={pageProps.team || undefined}>
                        {children}
                    </TeamProvider>
                </CategoriesProvider>
            </I18nextProvider>
        </LocaleProvider>
    );
};

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
    const router = useRouter();
    const getLayout = Component.getLayout ?? ((page: ReactNode) => page);

    useEffect(() => {
        // Function to load or reload theme.js
        const loadThemeJS = () => {
            const existingScript = document.querySelector('script[src="/assets/js/theme.min.js"]');
            if (existingScript) {
                existingScript.remove(); // Remove the existing script to ensure it reloads
            }
            const script = document.createElement("script");
            script.src = "/assets/js/theme.min.js";
            script.async = true;
            document.body.appendChild(script); // Add the script to the DOM
        };

        // Load theme.js when the page first loads
        loadThemeJS();

        // Re-load theme.js on route change
        const handleRouteChange = () => {
            loadThemeJS();
        };

        // Listen for route changes
        router.events.on("routeChangeComplete", handleRouteChange);

        // Cleanup event listener on unmount
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);

    return (
        <>
            <Script strategy="lazyOnload" src="/assets/js/theme.min.js" />
            <MainLayout header={pageProps.header} footer={pageProps.footer}>
                {getLayout(<Component {...pageProps} />)}
            </MainLayout>
        </>
    );
};

export default ({ Component, ...rest }: AppProps) => {
    return (
        <RuntimeConfigProvider
            config={rest.pageProps.runtimeConfig}
            commitHash={rest.pageProps.commitHash}>
            <Providers pageProps={rest.pageProps}>
                <App {...rest} Component={Component} />
            </Providers>
        </RuntimeConfigProvider>
    );
};
