import { SbBlokData } from "@storyblok/react";
import Link from "next/link";
import { FC } from "react";
import Img from "components/common/img/Img";
import { SbLink } from "types/storyblock";

interface IBrandsData {
    href: SbLink;
}

interface BrandsContent extends SbBlokData {
    title?: string;
    data?: IBrandsData[];
}

type Props = {
    blok: BrandsContent;
};

const Brands: FC<Props> = ({ blok }) => {
    return (
        <section className="container pb-3 pb-lg-5 mb-3 mb-xl-4">
            <h2 className="text-center pb-md-2">{blok.title}</h2>
            <div
                className="swiper mx-n2"
                data-swiper-options='{
"slidesPerView": 2,
"pagination": {
"el": ".swiper-pagination",
"clickable": true
},
"breakpoints": {
"500": {
  "slidesPerView": 3,
  "spaceBetween": 8
},
"650": {
  "slidesPerView": 4,
  "spaceBetween": 8
},
"900": {
  "slidesPerView": 5,
  "spaceBetween": 8
},
"1100": {
  "slidesPerView": 6,
  "spaceBetween": 8
}
}
}'>
                <div className="swiper-wrapper">
                    {blok.data?.map((brand, index) => (
                        <div key={index} className="swiper-slide py-3">
                            <Link
                                href={brand.href.url || "/"}
                                type="_blank"
                                className="card card-body card-hover px-2 mx-2">
                                <Img
                                    source="/assets/img/brands/01.svg"
                                    className="d-block mx-auto my-2"
                                    width="154"
                                    height={0}
                                    alt="Brand"
                                />
                            </Link>
                        </div>
                    ))}
                </div>

                {/* <!-- Pagination (bullets) --> */}
                <div className="swiper-pagination position-relative pt-2 mt-4"></div>
            </div>
        </section>
    );
};

export default Brands;
