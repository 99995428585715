import { FC, PropsWithChildren, useMemo, createContext } from "react";
import { ISbStoryData } from "@storyblok/react";
import { ICategoriesData, SbCategoriesContent } from "types/storyblock";

interface ICategoriesContext {
    categories: ICategoriesData[];
    selectedCategory?: ICategoriesData;
}

export const CategoriesContext = createContext<ICategoriesContext>(null as any);

type IProps = {
    defaultCategories?: ISbStoryData<SbCategoriesContent>;
    selectedCategory?: ICategoriesData;
};

export const CategoriesProvider: FC<IProps & PropsWithChildren> = ({
    children,
    defaultCategories,
    selectedCategory,
}) => {
    const categories = useMemo(() => defaultCategories?.content.data || [], [defaultCategories]);
    const selectedCategoryMemo = useMemo(() => selectedCategory, [selectedCategory]);
    const value = useMemo(
        () => ({
            categories,
            selectedCategory: selectedCategoryMemo,
        }),
        [categories, selectedCategoryMemo],
    );

    return <CategoriesContext.Provider value={value}>{children}</CategoriesContext.Provider>;
};
