import { SbBlokData, ISbRichtext } from "@storyblok/react";
import { FC } from "react";

interface ContactsContent extends SbBlokData {
    title?: string;
    address?: string;
    phone?: string;
    email?: SbBlokData;
    working_hours?: ISbRichtext;
}

type Props = {
    blok: ContactsContent;
};

export const Contacts: FC<Props> = ({ blok }) => {
    /* onst createMarkup = useCallback((storyblokHTML: ISbRichtext) => {
        const richTextResolver = new RichTextResolver();
        return {
            __html: richTextResolver.render(storyblokHTML),
        };
    }, []); */
    return (
        <section className="container pb-5 mb-1 mb-md-4 mb-lg-5">
            <div className="row pb-xl-3">
                <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0">
                    <div className="gallery rounded-3 shadow-sm">
                        {/* <a
                            href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d762.0820523476253!2d44.56272126965348!3d40.17950872285752!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abcafee44455d%3A0x63745d8693cec95a!2sDiaMed%20Medical%20Center!5e0!3m2!1sen!2sua!4v1720358554490!5m2!1sen!2sua"
                            data-iframe="true"
                            className="gallery-item rounded-3"
                            data-sub-html='<h6 className="fs-sm text-light">84 Hunan Avetisyan St, 84շենք, Armenia</h6>'>
                            <i className="bx bxs-map text-primary display-5 position-absolute top-50 start-50 translate-middle mb-0" />
                            <Img
                                source="/assets/img/landing/medical/map.jpg"
                                alt="Map cover"
                                width={0}
                                height={0}
                                style={{ width: "100%", height: "auto" }}
                            />
                            <div className="gallery-item-caption fs-sm fw-medium">
                                Expand the map
                            </div>
                        </a> */}
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3048.328072487421!2d44.56079007641854!3d40.17951177004241!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abcafee44455d%3A0x63745d8693cec95a!2sDiaMed%20Medical%20Center!5e0!3m2!1sen!2sam!4v1727697286225!5m2!1sen!2sam"
                            className="gallery-item rounded-3"
                            data-sub-html='<h6 className="fs-sm text-light">84 Hunan Avetisyan St, 84շենք, Armenia</h6>'
                            data-iframe="true"
                            width="600"
                            height="450"
                            style={{ border: 0 }}
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />
                    </div>
                </div>
                <div className="col-xl-5 col-md-6 offset-xl-1">
                    <h2 className="h1 pb-4 mb-1 mb-lg-3">{blok.title}</h2>
                    <ul className="list-unstyled pb-3 mb-0 mb-lg-3">
                        <li className="d-flex mb-3">
                            <i className="bx bx-map text-muted fs-xl mt-1 me-2"></i>
                            {blok.address}
                        </li>
                        <li className="d-flex mb-3">
                            <i className="bx bx-phone-call text-muted fs-xl mt-1 me-2"></i>
                            {blok.phone}
                        </li>
                        <li className="d-flex mb-3">
                            <i className="bx bx-time text-muted fs-xl mt-1 me-2"></i>
                            <div>
                                <div>
                                    <span className="text-dark fw-semibold me-1">Mon – Sat:</span>
                                    9:00 – 20:30
                                </div>
                                {/* <div>
                                    <span className="text-dark fw-semibold me-1">Sat – Sun:</span>
                                    9:00 am – 20:00 pm
                                </div> */}
                            </div>
                        </li>
                        <li className="d-flex mb-3">
                            <i className="bx bx-envelope text-muted fs-xl mt-1 me-2"></i>
                            <a href={`mailto:${blok.email?.url}`} className="fw-medium">
                                <>{blok.email?.url}</>
                            </a>
                        </li>
                    </ul>
                    {/* <a href="#" className="btn btn-primary shadow-primary btn-lg">
                        Make an appointment
                    </a> */}
                </div>
            </div>
        </section>
    );
};
