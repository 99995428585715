import { SbBlokData, storyblokEditable } from "@storyblok/react";
import { FC, PropsWithChildren, useMemo } from "react";
import { useRouter } from "next/router";
import Link, { LinkProps } from "next/link";
import clsx from "clsx";
import { Url } from "next/dist/shared/lib/router/router";
import { useTranslation } from "react-i18next";
import { SbLink } from "types/storyblock";

interface IProps {
    activeClassName: string;
    className?: string;
    href?: Url;
    includes?: string;
    forceActive?: boolean;
    blok: SBActiveLinkProps;
}

export interface SBActiveLinkProps extends SbBlokData {
    link: SbLink;
    name: string;
}

const ActiveLink: FC<IProps & LinkProps & PropsWithChildren> = ({
    activeClassName,
    includes,
    forceActive,
    href,
    as,
    className,
    blok,
    children,
    ...props
}) => {
    const { t } = useTranslation();
    const { asPath } = useRouter();

    const isActive: boolean =
        forceActive ||
        (includes && asPath.includes(includes)) ||
        asPath === as ||
        asPath === `/${blok.link.cached_url}` ||
        asPath === (typeof href === "string" ? href : href?.pathname);

    const hrefMemo = useMemo(() => {
        const isInternalLink = blok.link.linktype === "story";

        return blok
            ? `${isInternalLink && blok.link.cached_url[0] !== "/" ? "/" : ""}${
                  blok.link.cached_url
              }`
            : href;
    }, [blok, href]);

    return (
        <Link
            href={hrefMemo}
            {...storyblokEditable(blok)}
            className={clsx(className, isActive && ["activeLink", activeClassName])}
            {...props}>
            {children ||
                (blok.link.linktype === "story"
                    ? t(`pageName_${blok.link.cached_url.split("/").pop()}`)
                    : blok.name)}
        </Link>
    );
};

export default ActiveLink;
