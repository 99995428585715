import { SbBlokData, StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { FC, useMemo } from "react";

interface SbSliderContent extends SbBlokData {
    social_platform_links?: SbBlokData[];
    slides?: SbBlokData[];
}

type Props = {
    blok: SbSliderContent;
};

const Slider: FC<Props> = ({ blok }) => {
    const swiperConfig = useMemo(
        () =>
            JSON.stringify({
                effect: "fade",
                speed: 500,
                loop: true,
                grabCursor: true,
                autoplay: {
                    delay: 5500,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    prevEl: "#hero-prev",
                    nextEl: "#hero-next",
                },
            }),
        [],
    );

    if (!blok) {
        return null;
    }

    return (
        <section
            {...storyblokEditable(blok)}
            className="jarallax bg-dark py-xxl-5"
            data-jarallax
            data-speed="0.4"
            data-bs-theme="dark">
            <span className="position-absolute top-0 start-0 w-100 h-100 bg-gradient-dark-translucent"></span>
            <div
                className="jarallax-img"
                style={{
                    backgroundImage: "url(assets/img/landing/software-agency-1/hero-bg.jpg)",
                }}></div>
            <div className="position-relative text-center zindex-5 overflow-hidden pt-4 py-md-5">
                {/* <!-- Slider controls (Prev / next) --> */}
                <button
                    type="button"
                    id="hero-prev"
                    className="btn btn-prev btn-icon btn-xl bg-transparent shadow-none position-absolute top-50 start-0 translate-middle-y d-none d-md-inline-flex ms-n3 ms-lg-2"
                    aria-label="Previous">
                    <i className="bx bx-chevron-left fs-1"></i>
                </button>
                <button
                    type="button"
                    id="hero-next"
                    className="btn btn-next btn-icon btn-xl bg-transparent shadow-none position-absolute top-50 end-0 translate-middle-y d-none d-md-inline-flex me-n3 me-lg-2"
                    aria-label="Next">
                    <i className="bx bx-chevron-right fs-1"></i>
                </button>

                {/* <!-- Slider --> */}
                <div className="container text-center py-xl-5">
                    <div className="row justify-content-center pt-lg-5">
                        <div className="col-xl-8 col-lg-9 col-md-10 col-11">
                            <div
                                className="swiper pt-5 pb-4 py-md-5"
                                data-swiper-options={swiperConfig}>
                                <div className="swiper-wrapper">
                                    {blok.slides?.map((item) => (
                                        <StoryblokComponent key={item._uid} blok={item} />
                                    ))}
                                </div>

                                {/* <!-- Pagination (bullets) --> */}
                                <div className="swiper-pagination position-relative d-md-none pt-2 mt-5"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="container d-flex flex-column flex-sm-row align-items-center justify-content-between position-relative zindex-5 pb-5">
                <div className="d-flex mb-4 mb-sm-0">
                    <a
                        href="#"
                        className="btn btn-icon btn-secondary btn-linkedin rounded-circle me-2"
                        aria-label="LinkedIn">
                        <i className="bx bxl-linkedin"></i>
                    </a>
                    <a
                        href="#"
                        className="btn btn-icon btn-secondary btn-facebook rounded-circle me-2"
                        aria-label="Facebook">
                        <i className="bx bxl-facebook"></i>
                    </a>
                    <a
                        href="#"
                        className="btn btn-icon btn-secondary btn-twitter rounded-circle me-2"
                        aria-label="Twitter">
                        <i className="bx bxl-twitter"></i>
                    </a>
                    <a
                        href="#"
                        className="btn btn-icon btn-secondary btn-youtube rounded-circle me-2"
                        aria-label="YouTube">
                        <i className="bx bxl-youtube"></i>
                    </a>
                </div>
                <a href="#" className="nav-link px-0">
                    Our case studies
                    <i className="bx bx-right-arrow-circle fs-4 ms-2"></i>
                </a>
            </div> */}
        </section>
    );
};

export default Slider;
