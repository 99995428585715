import { SbBlokData } from "@storyblok/react";
import { FC } from "react";
import Img from "components/common/img/Img";

interface StatsContent extends SbBlokData {
    title?: string;
    data?: IStatsData[];
}

interface IStatsData {
    amount: string;
    text: string;
}

type Props = {
    blok: StatsContent;
};

const Stats: FC<Props> = ({ blok }) => {
    return (
        <section className="container pb-5 mb-2 mb-md-4 mb-lg-5">
            <div className="bg-secondary rounded-3 py-5 px-3 px-md-0">
                <div className="row justify-content-center mb-2 py-md-2 py-lg-4">
                    <div className="col-lg-10 col-md-11">
                        <h2 className="pb-3">{blok.title}</h2>
                        <div className="row row-cols-1 row-cols-md-2 g-4">
                            {blok.data?.map((stat, index) => (
                                <div key={index} className="col">
                                    <div className="card h-100 border-0 shadow-sm p-md-2 p-lg-4">
                                        <div className="card-body d-flex align-items-center">
                                            <div className="bg-primary shadow-primary rounded-3 flex-shrink-0 p-3">
                                                <Img
                                                    source="/assets/img/about/icons/headset-light.svg"
                                                    className="d-block m-1"
                                                    width="40"
                                                    height={0}
                                                    alt="Icon"
                                                />
                                            </div>
                                            <div className="ps-4 ms-lg-3">
                                                <h3 className="display-5 mb-1">{stat.amount}</h3>
                                                <p className="mb-0">
                                                    <span className="fw-semibold">{stat.text}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Stats;
