import { FC, PropsWithChildren, useMemo, createContext } from "react";
import { ISbStoryData } from "@storyblok/react";
import { ITeamData, SbTeamContent } from "types/storyblock";

interface ITeamContext {
    team: ITeamData[];
}

export const TeamContext = createContext<ITeamContext>(null as any);

type IProps = {
    defaultTeam?: ISbStoryData<SbTeamContent>;
};

export const TeamProvider: FC<IProps & PropsWithChildren> = ({ children, defaultTeam }) => {
    const team = useMemo(() => defaultTeam?.content.data || [], [defaultTeam]);
    const value = useMemo(
        () => ({
            team,
        }),
        [team],
    );

    return <TeamContext.Provider value={value}>{children}</TeamContext.Provider>;
};
