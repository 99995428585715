import Link from "next/link";
import { FC, useCallback } from "react";
import { StoryblokComponent } from "@storyblok/react";
import { useTranslation } from "react-i18next";
import Img from "components/common/img/Img";
import { SbAsset, SbFooterContent } from "types/storyblock";
import { useRuntimeConfig } from "hooks/useRuntimeConfig";
import { SBActiveLinkProps } from "components/common/active-link/ActiveLink";

type IProps = {
    content: SbFooterContent;
    logo: SbAsset;
    phone: string;
};

const Footer: FC<IProps> = ({ content, logo, phone }) => {
    const APP_NAME = useRuntimeConfig("APP_NAME");
    const RELEASE_VERSION = useRuntimeConfig("RELEASE_VERSION");
    const { t } = useTranslation();

    const renderSocialLink = useCallback((blok: SBActiveLinkProps) => {
        if (blok.link.cached_url.includes("facebook")) {
            return (
                <a
                    key={blok._uid}
                    target="_blank"
                    href={blok.link.cached_url}
                    className="btn btn-icon btn-secondary btn-facebook me-3"
                    aria-label={blok.name}>
                    <i className="bx bxl-facebook"></i>
                </a>
            );
        } else if (blok.link.cached_url.includes("instagram")) {
            return (
                <a
                    key={blok._uid}
                    target="_blank"
                    href={blok.link.cached_url}
                    className="btn btn-icon btn-secondary btn-instagram me-3"
                    aria-label={blok.name}>
                    <i className="bx bxl-instagram"></i>
                </a>
            );
        } else if (blok.link.cached_url.includes("twitter")) {
            return (
                <a
                    key={blok._uid}
                    target="_blank"
                    href={blok.link.cached_url}
                    className="btn btn-icon btn-secondary btn-twitter me-3"
                    aria-label={blok.name}>
                    <i className="bx bxl-twitter"></i>
                </a>
            );
        } else if (blok.link.cached_url.includes("youtube")) {
            return (
                <a
                    key={blok._uid}
                    target="_blank"
                    href={blok.link.cached_url}
                    className="btn btn-icon btn-secondary btn-youtube"
                    aria-label={blok.name}>
                    <i className="bx bxl-youtube"></i>
                </a>
            );
        }
        return null;
    }, []);

    return (
        <footer className="footer bg-dark pt-5 pb-4 pb-lg-5" data-bs-theme="dark">
            <div className="container pt-lg-4">
                <div className="row pb-5">
                    <div className="col-lg-4 col-md-6">
                        <Link href="/" className="navbar-brand text-dark p-0 me-0 mb-3 mb-lg-4">
                            {logo?.filename ? (
                                <Img
                                    source={logo.filename}
                                    alt={`${APP_NAME} logo`}
                                    title={`${APP_NAME} logo`}
                                    width={115}
                                    height={0}
                                    // style={{ width: "100%", height: "auto" }}
                                />
                            ) : (
                                APP_NAME
                            )}
                        </Link>
                        {phone && (
                            <a
                                href={`tel:${phone}`}
                                className="btn btn-primary btn-sm fs-sm rounded d-none d-lg-inline-flex"
                                target="_blank"
                                rel="noopener">
                                <i className="bx bx-phone-call fs-5 lh-1 me-1" />
                                {t("base_call")}
                            </a>
                        )}
                        {/* <p className="fs-sm text-light opacity-70 pb-lg-3 mb-4">
                            Proin ipsum pharetra, senectus eget scelerisque varius pretium platea
                            velit. Lacus, eget eu vitae nullam proin turpis etiam mi sit. Non
                            feugiat feugiat egestas nulla nec. Arcu tempus, eget elementum dolor
                            ullamcorper sodales ultrices eros.
                        </p> */}
                        {/* <form className="needs-validation" noValidate>
                            <label htmlFor="subscr-email" className="form-label">
                                Subscribe to our newsletter
                            </label>
                            <div className="input-group">
                                <input
                                    type="email"
                                    id="subscr-email"
                                    className="form-control rounded-start ps-5"
                                    placeholder="Your email"
                                    required
                                />
                                <i className="bx bx-envelope fs-lg text-muted position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"></i>
                                <div className="invalid-tooltip position-absolute top-100 start-0">
                                    Please provide a valid email address.
                                </div>
                                <button type="submit" className="btn btn-primary">
                                    Subscribe
                                </button>
                            </div>
                        </form> */}
                    </div>
                    <div className="col-xl-6 col-lg-7 col-md-5 offset-xl-2 offset-md-1 pt-4 pt-md-1 pt-lg-0">
                        <div id="footer-links" className="row">
                            <div className="col-lg-4">
                                <h6 className="mb-2">
                                    <a
                                        href="#useful-links"
                                        className="d-block text-dark dropdown-toggle d-lg-none py-2"
                                        data-bs-toggle="collapse">
                                        Useful Links
                                    </a>
                                </h6>
                                <div
                                    id="useful-links"
                                    className="collapse d-lg-block"
                                    data-bs-parent="#footer-links">
                                    <ul className="nav flex-column pb-lg-1 mb-lg-3">
                                        {content.footer_nav?.map((link) => (
                                            <li className="nav-item" key={link._uid}>
                                                <StoryblokComponent
                                                    blok={link}
                                                    className="nav-link d-inline-block px-0 pt-1 pb-2"
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-3">
                                {/* <h6 className="mb-2">
                                    <a
                                        href="#social-links"
                                        className="d-block text-dark dropdown-toggle d-lg-none py-2"
                                        data-bs-toggle="collapse">
                                        Socials
                                    </a>
                                </h6>
                                <div
                                    id="social-links"
                                    className="collapse d-lg-block"
                                    data-bs-parent="#footer-links">
                                    <ul className="nav flex-column mb-2 mb-lg-0">
                                        {content.social_links?.map((link) => (
                                            <li className="nav-item" key={link._uid}>
                                                <StoryblokComponent
                                                    target="_blank"
                                                    blok={link}
                                                    className="nav-link d-inline-block px-0 pt-1 pb-2"
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </div> */}
                                <div className="d-flex pt-1 pt-md-3 pt-xl-4">
                                    {(content.social_links as SBActiveLinkProps[])?.map(
                                        renderSocialLink,
                                    )}
                                </div>
                            </div>
                            {/* <div className="col-xl-4 col-lg-5 pt-2 pt-lg-0">
                                <a href={`mailto:${content.email?.url}`} className="fw-medium">
                                    <>{content.email?.url}</>
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>
                <p className="nav d-block fs-xs text-center text-md-start pb-2 pb-lg-0 mb-0">
                    <span className="text-light opacity-50">
                        {`© ${new Date().getFullYear()} ${APP_NAME} ${
                            RELEASE_VERSION !== "local" ? `v.${RELEASE_VERSION}` : ""
                        }`}
                    </span>
                </p>
            </div>
        </footer>
    );
};

export default Footer;
