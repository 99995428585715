import { FC, useContext, useMemo } from "react";
import Link from "next/link";
import { SbBlokData } from "@storyblok/react";
import { splitArray } from "utils";
import { CategoriesContext } from "providers/categories.provider";
import { RouteNames } from "utils/enums";
import Img from "components/common/img/Img";

interface CategoriesContent extends SbBlokData {
    heading?: string;
    description?: string;
}

type Props = {
    blok: CategoriesContent;
};

export const Categories: FC<Props> = ({ blok }) => {
    const { categories } = useContext(CategoriesContext);
    const columns = useMemo(() => splitArray(categories, 2), [categories]);

    return (
        <section className="container pb-5 mb-md-2 mb-lg-5">
            <div className="row">
                <div className="col-lg-4 text-center text-lg-start pb-3 pb-lg-0 mb-4 mb-lg-0">
                    <h2 className="h1 mb-lg-4">{blok.heading}</h2>
                    <p className="pb-4 mb-0 mb-lg-3">{blok.description}</p>
                    <Link
                        href={`/${RouteNames.Categories}`}
                        className="btn btn-primary shadow-primary btn-lg">
                        Բոլոր ծառայությունները
                    </Link>
                </div>
                <div className="col-xl-7 col-lg-8 offset-xl-1">
                    <div className="row row-cols-1 row-cols-md-2">
                        {columns.map((column, index) => (
                            <div key={index} className="col">
                                {column.map((category) => (
                                    <Link
                                        key={category.label}
                                        href={`/${RouteNames.Categories}/${category.slug}`}>
                                        <div className="card card-hover bg-secondary border-0 mb-4">
                                            <div className="card-body d-flex align-items-start">
                                                <div className="flex-shrink-0 bg-light rounded-3 p-3">
                                                    <Img
                                                        source={category.icon.filename}
                                                        width="28"
                                                        height={0}
                                                        alt="Icon"
                                                    />
                                                </div>
                                                <div className="ps-4">
                                                    <h3 className="h5 pb-2 mb-1">
                                                        {category.label}
                                                    </h3>
                                                    {/* <p className="pb-2 mb-1">{category.short_text}</p> */}
                                                    {/* <Link
                                                    href={`/${RouteNames.Categories}/${category.slug}`}
                                                    className="btn btn-link stretched-link px-0">
                                                    Learn more
                                                    <i className="bx bx-right-arrow-alt fs-xl ms-2"></i>
                                                </Link> */}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};
