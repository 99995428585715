import { SbBlokData } from "@storyblok/react";
import Link from "next/link";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import Img from "components/common/img/Img";
import { RouteNames } from "utils/enums";
import { SbAsset } from "types/storyblock";

interface HeroContent extends SbBlokData {
    heading?: string;
    slogan?: string;
    img_left?: SbAsset;
    img_right?: SbAsset;
}

type Props = {
    blok: HeroContent;
};

export const Hero: FC<Props> = ({ blok }) => {
    const { t } = useTranslation();
    return (
        <section className="position-relative pt-md-3 pt-lg-5 mb-md-3 mb-lg-5">
            <div className="container position-relative zindex-5 pt-5">
                <div className="row mt-4 pt-5">
                    <div className="col-xl-4 col-lg-5 text-center text-lg-start pb-3 pb-md-4 pb-lg-0">
                        <h1 className="fs-xl text-uppercase">{blok.heading}</h1>
                        <h3 className="display-4 pb-md-2 pb-lg-4">{blok.slogan}</h3>
                        {/* <p className="fs-lg">
                            Dont have insurance?{" "}
                            <a href="#" className="fw-medium">
                                Click here.
                            </a>
                        </p> */}
                    </div>
                    {/* <div className="col-xl-5 col-lg-6 offset-xl-1 position-relative zindex-5 mb-5 mb-lg-0">
                        <div
                            className="rellax card bg-primary border-0 shadow-primary py-2 p-sm-4 p-lg-5"
                            data-rellax-speed="-1"
                            data-disable-parallax-down="lg">
                            <div className="card-body p-lg-3">
                                <h2 className="text-light pb-1 pb-md-3">{content.heading}</h2>
                                <p className="fs-lg text-light pb-2 pb-md-0 mb-4 mb-md-5">
                                    ԴԻԱՄԵԴ բժշկական կենտրոնը մասնագիտացված է ողնաշարային
                                    հիվանդությունների բուժման արդյունավետ տարբեր միջոցների
                                    կիրառմամբ: Մենք ուսումնասիրել ենք ողնաշարի ճողվածքների բուժման
                                    համաշխարհային, մասնավորապես ամերիկյան փորձը։ Կենտրոնը
                                    տարածաշրջանում նման ուղղվածությամբ առաջիններից մեկն է:
                                </p>
                                <a href="#" className="btn btn-light btn-lg">
                                    Մեր Մասին
                                    <i className="bx bx-right-arrow-alt lh-1 fs-4 ms-2 me-n2"></i>
                                </a>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="d-none d-lg-block" style={{ marginTop: "-165px" }}></div>
                <div className="row align-items-end">
                    <div className="col-lg-6 d-none d-lg-block">
                        <Img
                            source={
                                blok.img_left?.filename ||
                                "assets/img/landing/medical/hero-img-2.jpg"
                            }
                            className="rellax rounded-3"
                            alt={blok.img_left?.alt || ""}
                            data-rellax-speed="1.35"
                            data-disable-parallax-down="md"
                            width={0}
                            height={0}
                            style={{ width: "100%", height: "auto" }}
                        />
                    </div>
                    <div className="col-lg-6 d-flex flex-column flex-md-row align-items-center justify-content-between">
                        <div className="d-flex align-items-center ps-xl-5 mb-4 mb-md-0">
                            <div className="btn btn-icon btn-secondary btn-lg pe-none rounded d-lg-none d-xl-inline-flex">
                                <i className="bx bx-time text-primary fs-3"></i>
                            </div>
                            <ul className="list-unstyled ps-3 ps-lg-0 ps-xl-3 mb-0">
                                <li>
                                    <strong className="text-dark">Mon – Sat:</strong> 9:00 – 20:30
                                </li>
                            </ul>
                        </div>
                        <Link
                            href={`/${RouteNames.About}`}
                            className="btn btn-primary btn-lg shadow-primary">
                            {t("pageName_about")}
                        </Link>
                    </div>
                </div>
            </div>
            <div
                className="d-none d-lg-block position-absolute top-0 end-0 w-50 d-flex flex-column ps-3"
                style={{ height: "calc(100% - 108px)" }}>
                <div
                    className="w-100 h-100 overflow-hidden bg-position-center bg-repeat-0 bg-size-cover"
                    style={{
                        backgroundImage: `url(${
                            blok.img_right?.filename || "assets/img/landing/medical/hero-img-1.jpg"
                        })`,
                        borderBottomLeftRadius: ".5rem",
                    }}></div>
            </div>
        </section>
    );
};
