import { FC, useMemo } from "react";
import { SbBlokData, StoryblokComponent } from "@storyblok/react";
import Img from "../img/Img";
import { SbAsset } from "types/storyblock";

interface BannerContent extends SbBlokData {
    img?: SbAsset;
    heading?: string;
    text?: string;
    button?: SbBlokData[];
}

interface CarouselContent extends SbBlokData {
    banners: BannerContent[];
    layout: "default" | "banner";
}

type Props = {
    blok: CarouselContent;
};

const Carousel: FC<Props> = ({ blok }) => {
    const swiperConfig = useMemo(
        () =>
            JSON.stringify({
                spaceBetween: 30,
                grabCursor: true,
                // slidesPerView: 1.5,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: true,
                },
                loop: true,
                tabs: blok.layout === "default",
                navigation: {
                    prevEl: "#prev",
                    nextEl: "#next",
                },
                zoom: {
                    minRatio: 1,
                    maxRatio: 1.5,
                },
            }),
        [blok.layout],
    );

    const renderLayout = useMemo(() => {
        switch (blok.layout) {
            case "banner":
                return (
                    <div style={{ marginTop: "-80px" }}>
                        <div
                            className="swiper mx-0 mb-md-n2 mb-xxl-n3"
                            data-swiper-options={swiperConfig}>
                            <div className="swiper-wrapper text-center text-lg-start">
                                {blok.banners.map((banner) => (
                                    <div key={banner._uid} className="swiper-slide pb-4">
                                        <div className="swiper-zoom-container">
                                            <Img
                                                source={
                                                    banner.img?.filename ||
                                                    "assets/img/landing/medical/cta/01.jpg"
                                                }
                                                className="rounded-3"
                                                alt="Image"
                                                width={0}
                                                height={0}
                                                style={{ width: "100%", height: "auto" }}
                                            />
                                        </div>
                                    </div>
                                ))}
                                {/* <div className="swiper-slide pb-4" data-swiper-tab="#image-1">
                                        <h2 className="h1 mb-4">
                                            New Service — Start Your Care{" "}
                                            <span className="text-success">Online</span> Now
                                        </h2>
                                        <p className="pb-2 pb-xl-0 mb-4 mb-xl-5">
                                            Morbi lacus vulputate mauris ut et nunc, tempor.
                                            Placerat augue eu amet feugiat mi sagittis velit. Sed
                                            suscipit nunc suspendisse morbi pharetra libero
                                            consectetur. Proin eros sollicitudin augue tempus.
                                            Aliquet id sit donec aliquam.
                                        </p>
                                        <a
                                            href="#"
                                            className="btn btn-success btn-lg shadow-success">
                                            Learn more
                                        </a>
                                    </div>

                                    <div className="swiper-slide pb-4" data-swiper-tab="#image-2">
                                        <h2 className="h1 mb-4">
                                            Support Groups for{" "}
                                            <span className="text-danger">Depression</span> &amp;
                                            Anxiety
                                        </h2>
                                        <p className="pb-2 pb-xl-0 mb-4 mb-xl-5">
                                            Magna cursus feugiat sed sodales praesent vehicula
                                            integer arcu. Felis duis lectus felis, tempus aliquet
                                            quis non. At integer consectetur eget nunc, fames. Et
                                            facilisi vel, luctus sed interdum vitae nec, velit.
                                            Maecenas purus et pharetra, at volutpat aenean.
                                        </p>
                                        <a href="#" className="btn btn-danger btn-lg shadow-danger">
                                            Learn more
                                        </a>
                                    </div> */}
                            </div>
                        </div>
                    </div>
                );
            default:
                return (
                    <>
                        <div className="col-xl-5 col-lg-6" style={{ marginTop: "-80px" }}>
                            <div className="swiper-tabs mx-auto" style={{ maxWidth: "526px" }}>
                                {blok.banners.map((banner, index) => (
                                    <div
                                        key={banner._uid}
                                        id={`image-${index + 1}`}
                                        className="swiper-tab">
                                        <Img
                                            source={
                                                banner.img?.filename ||
                                                "assets/img/landing/medical/cta/01.jpg"
                                            }
                                            className="rounded-3"
                                            alt="Image"
                                            width={0}
                                            height={0}
                                            style={{ width: "100%", height: "auto" }}
                                        />
                                    </div>
                                ))}
                                {/* <div id="image-4" className="swiper-tab active">
                                    <Img
                                        source="assets/img/landing/medical/cta/01.jpg"
                                        className="rounded-3"
                                        alt="Image"
                                        width={0}
                                        height={0}
                                        style={{ width: "100%", height: "auto" }}
                                    />
                                </div>
                                <div id="image-5" className="swiper-tab">
                                    <Img
                                        source="assets/img/landing/medical/cta/02.jpg"
                                        className="rounded-3"
                                        alt="Image"
                                        width={0}
                                        height={0}
                                        style={{ width: "100%", height: "auto" }}
                                    />
                                </div> */}
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 offset-xl-1 pt-2 pt-md-4 pt-lg-5 mt-4">
                            {/* <div className="d-flex justify-content-center justify-content-lg-start pb-3 mb-3">
                                <button
                                    type="button"
                                    id="prev"
                                    className="btn btn-prev btn-icon btn-sm me-2"
                                    aria-label="Previous">
                                    <i className="bx bx-chevron-left"></i>
                                </button>
                                <button
                                    type="button"
                                    id="next"
                                    className="btn btn-next btn-icon btn-sm ms-2"
                                    aria-label="Next">
                                    <i className="bx bx-chevron-right"></i>
                                </button>
                            </div> */}

                            <div
                                className="swiper mx-0 mb-md-n2 mb-xxl-n3"
                                data-swiper-options={swiperConfig}>
                                <div className="swiper-wrapper text-center text-lg-start">
                                    {blok.banners.map((banner, index) => (
                                        <div
                                            key={banner._uid}
                                            className="swiper-slide pb-4"
                                            data-swiper-tab={`#image-${index + 1}`}>
                                            {banner.heading && (
                                                <h2 className="h1 mb-4">
                                                    {banner.heading}
                                                    {/* New Service — Start Your Care{" "}
                                                    <span className="text-success">Online</span> Now */}
                                                </h2>
                                            )}
                                            {banner.text && (
                                                <p className="pb-2 pb-xl-0 mb-4 mb-xl-5">
                                                    {banner.text}
                                                </p>
                                            )}

                                            {banner.button?.[0] && (
                                                <StoryblokComponent blok={banner.button} />
                                            )}

                                            <a
                                                href="#"
                                                className="btn btn-success btn-lg shadow-success">
                                                Learn more
                                            </a>
                                        </div>
                                    ))}
                                    {/* <div className="swiper-slide pb-4" data-swiper-tab="#image-1">
                                        <h2 className="h1 mb-4">
                                            New Service — Start Your Care{" "}
                                            <span className="text-success">Online</span> Now
                                        </h2>
                                        <p className="pb-2 pb-xl-0 mb-4 mb-xl-5">
                                            Morbi lacus vulputate mauris ut et nunc, tempor.
                                            Placerat augue eu amet feugiat mi sagittis velit. Sed
                                            suscipit nunc suspendisse morbi pharetra libero
                                            consectetur. Proin eros sollicitudin augue tempus.
                                            Aliquet id sit donec aliquam.
                                        </p>
                                        <a
                                            href="#"
                                            className="btn btn-success btn-lg shadow-success">
                                            Learn more
                                        </a>
                                    </div>

                                    <div className="swiper-slide pb-4" data-swiper-tab="#image-2">
                                        <h2 className="h1 mb-4">
                                            Support Groups for{" "}
                                            <span className="text-danger">Depression</span> &amp;
                                            Anxiety
                                        </h2>
                                        <p className="pb-2 pb-xl-0 mb-4 mb-xl-5">
                                            Magna cursus feugiat sed sodales praesent vehicula
                                            integer arcu. Felis duis lectus felis, tempus aliquet
                                            quis non. At integer consectetur eget nunc, fames. Et
                                            facilisi vel, luctus sed interdum vitae nec, velit.
                                            Maecenas purus et pharetra, at volutpat aenean.
                                        </p>
                                        <a href="#" className="btn btn-danger btn-lg shadow-danger">
                                            Learn more
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </>
                );
        }
    }, [blok.banners, blok.layout, swiperConfig]);

    return (
        <section className="pb-5 mb-2 mb-md-4 mb-lg-5" style={{ paddingTop: "80px" }}>
            <div className="bg-secondary pb-lg-4 pb-xl-5">
                <div className="container pb-4 pb-md-5">
                    <div className="row">{renderLayout}</div>
                </div>
            </div>
        </section>
    );
};

export default Carousel;
